import { render, staticRenderFns } from "./BannerVideo.vue?vue&type=template&id=380e41a8&scoped=true"
import script from "./BannerVideo.vue?vue&type=script&lang=js"
export * from "./BannerVideo.vue?vue&type=script&lang=js"
import style0 from "./BannerVideo.vue?vue&type=style&index=0&id=380e41a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "380e41a8",
  null
  
)

export default component.exports