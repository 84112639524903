<template>
  <section class="detail container-fluid">
    <b-row>
      <b-col lg="12" class="col-lg-12">
        <div class="trending-info g-border">
          <h1 class="trending-text big-title text-uppercase mt-0"></h1>
          <!-- <p class="trending-dec w-100 mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                        unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                        survived not only five centuries.</p> -->
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<script>
export default {
  name: "Detail",
  props: ["video"],
  components: {},
  mounted() {},
  data() {
    return {}
  }
}
</script>
