<template>
  <div>
    <div
      v-if="video.content_url?.[$i18n.locale] != null"
      class="video-container iq-main-slider"
    >
      <br />

      <div v-if="video.content_type === 'Image'">
        <div
          id="imagePopup"
          :class="{
            active: showImagePopup,
            disabled: !showImagePopup,
            imagePopup: true
          }"
        >
          <div @click="closeContent">
            <i class="fa fa-times closeBtn"></i>
          </div>
          <img :src="imageContentPath" alt="Image" />
        </div>

        <img
          :src="video.content_url?.[$i18n.locale]"
          alt="Image"
          class="imagestyle"
          @click.prevent="openContent(video.content_url?.[$i18n.locale])"
        />
      </div>

      <div v-if="video.content_type === 'Video'">
        <vue-plyr :options="options">
          <video controls data-poster="poster.jpg">
            <source
              :src="video.content_url[$i18n.locale]"
              size="720"
              type="video/mp4"
            />
          </video>
        </vue-plyr>
      </div>

      <div v-if="video.content_type === 'Audio'" class="audio-section px-3">
        <img
          v-if="video.content_thumbnail != null"
          :src="video.content_thumbnail"
          alt="audio"
        />
        <img v-else alt="Image" src="../../../assets/images/thumbnail.jpg" />

        <vue-plyr :options="options">
          <audio controls data-poster="poster.jpg">
            <source
              :src="video.content_url[$i18n.locale]"
              size="720"
              type="audio/mp3"
            />
          </audio>
        </vue-plyr>
      </div>

      <div v-if="video.content_type === 'Text'">
        <img
          v-if="video.content_thumbnail != null"
          style="z-index: 1; filter: blur(4px) !important"
          :src="video.content_thumbnail"
          alt="text"
        />
        <img
          v-else
          style="filter: blur(4px) !important"
          alt="Image"
          src="../../../assets/images/thumbnail.jpg"
        />

        <div class="overlay"></div>

        <div class="text-type">
          <h4>
            {{ video.content_title[$i18n.locale] }}
          </h4>
        </div>
      </div>
    </div>

    <div
      v-show="video.content_url?.[$i18n.locale] == null"
      class="video-container iq-main-slider"
    ></div>
  </div>
</template>
<script>
import AlertServie from "../../../services/errors"
import VuePlyr from "vue-plyr"
import "vue-plyr/dist/vue-plyr.css"

export default {
  name: "BannerVideo",
  props: ["video"],

  data() {
    return {
      activeTab: "images",
      options: { quality: { default: "1080p" } },
      showImagePopup: false,
      imageContentPath: "",
      favOption: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        prevArrow:
          '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
        nextArrow:
          '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },

  components: { VuePlyr },
  mounted() {
    var videoPlayer = document.getElementById("videoPlayer")

    // Add an event listener to detect when the video ends
    videoPlayer.addEventListener("ended", function () {
      // Send a message to the parent page indicating that the video has ended
      if (this.$i18n.locale === "en") {
        AlertServie.info('to rewatch this content go to "My Contents"')
      } else if (this.$i18n.locale === "ar") {
        AlertServie.info("لإعادة مشاهدة هذا المحتوى انتقل إلى (محتوياتي)")
      } else {
        AlertServie.info("بۆ دووبارە سەیرکردنی ئەم ناوەڕۆکە بڕۆ بۆ (لیستەکەم)")
      }
    })
  },

  methods: {
    closeContent() {
      this.imageContentPath = ""
      this.showImagePopup = false
      //  this.imagePopup = false
    },
    openContent(imageurl) {
      this.showImagePopup = true
      var self = this
      self.imageContentPath = imageurl
    }
  }
}
</script>

<style lang="scss" scoped>
.text-type {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(-50%, -50%);
  text-align: center !important;
  z-index: 2;
  width: 100%;
  color: #fff;
  padding: 0px 10px;
  font-size: 12px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  // background: linear-gradient(
  //   90deg,
  //   rgba(0, 0, 0, 0.8) 0%,
  //   rgba(20, 20, 20, 0.4) 50%,
  //   rgba(83, 100, 141, 0) 100%
  // ) !important;
  z-index: 2;
}

.audio-section {
  margin-top: 20px !important;
}

.audio-section img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.imagePopup.active,
.textPopup.active {
  display: block;
  z-index: 999;
}

.imagePopup {
  max-height: 80vh;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: 80% !important;
  //  max-height: 80vh;
  aspect-ratio: 1/1;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(96vw);
    height: fit-content;
  }

  @media (min-width: 768px) {
    width: fit-content;
    height: fit-content;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
    font-weight: 1200;
    font-size: 40px;
    color: #ddd;
  }

  .prev {
    left: 20px;
    transform: translateY(-50%);
  }

  .next {
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    z-index: 999;
    overflow: hidden;
    cursor: pointer;
  }
}

.main-title {
  font-size: 22px;
  align-items: left;
  padding-right: 1240px;
  padding-top: 100px;
}

.imagePopup.active,
.textPopup.active {
  display: block;
  z-index: 999;
}

.imagePopup {
  max-height: 80vh;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: fit-content;
  //  max-height: 80vh;
  aspect-ratio: 1/1;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(96vw);
    height: fit-content;
  }

  @media (min-width: 768px) {
    width: fit-content;
    height: fit-content;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
    font-weight: 1200;
    font-size: 40px;
    color: #ddd;
  }

  .prev {
    left: 20px;
    transform: translateY(-50%);
  }

  .next {
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    z-index: 999;
    overflow: hidden;
    cursor: pointer;
  }
}

.tabs {
  display: flex;
}

button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

button.active {
  background: #131a21;
  color: #f0f0f0;
}

.tab-content {
  margin-top: 20px;
}
</style>
